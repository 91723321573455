const flipButton = document.getElementById("btn-flip");
const btnInfo = document.getElementById("btn-info");
const btnSocial = document.getElementById("btn-social");
const btnAppointment = document.getElementById("btn-appointment")
const btnQr = document.getElementById("btn-qr");
const btnContact = document.getElementById("btn-contact");

const cardContainer = document.querySelector(".card-container");

if (cardContainer) {
  cardContainer.addEventListener("click", function (e) {
    e.preventDefault();

    if (cardContainer.classList.contains("card-flipped")) {
      cardContainer.classList.remove("card-flipped");
    } else {
      cardContainer.classList.add("card-flipped");
    }
  });

  if (flipButton) {
    flipButton.addEventListener("click", function (e) {
      e.preventDefault();

      if (!window.CardViewer) {
        return;
      }

      window.CardViewer.flip();
    });
  }
}

if (btnInfo) {
  btnInfo.addEventListener("click", function (e) {
    e.preventDefault();
    e.stopPropagation();

    if (!window.CardViewer) {
      return;
    }

    const cardSocial = document.querySelector(".card-social");
    const cardInfo = document.querySelector(".card-info");

    cardSocial.classList.add("d-none");
    cardInfo.classList.remove("d-none");

    window.CardViewer.toggleUI();
    flipButton.classList.toggle("disabled");
  });
}

if (btnSocial) {
  btnSocial.addEventListener("click", function (e) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();

    if (!window.CardViewer) {
      return;
    }

    const cardSocial = document.querySelector(".card-social");
    const cardInfo = document.querySelector(".card-info");

    cardSocial.classList.remove("d-none");
    cardInfo.classList.add("d-none");

    window.CardViewer.toggleUI();
    flipButton.classList.toggle("disabled");
  });
}

if (btnAppointment) {
  btnAppointment.addEventListener("click", function (e) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();

    window.open(e.currentTarget.dataset.url, '_blank').focus();
  });
}

if (btnQr) {
  btnQr.addEventListener("click", function (e) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
  });
}

if (btnContact) {
  btnContact.addEventListener("click", function (e) {
    e.stopPropagation();
    e.stopImmediatePropagation();
  });
}
